import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import { convertHtmlToText } from '../utils/utils'
import PopularQuestion from "../components/Questions/popularQuestion/PopularQuestion";

export default function blogfertile({ pageContext }) {
    var questionID = pageContext.product.node.metadata.id;
    pageContext = pageContext.product.node.metadata;

    return (
        <Layout>
            <SEO
                image={pageContext?.meta_image?.url}
                title={
                    pageContext.question.length > 50
                        ? pageContext.question.slice(0, 50) + "..."
                        : pageContext.question
                }
                description={
                    pageContext.answer.length > 150
                        ? convertHtmlToText(pageContext.answer).slice(0, 150) + "..."
                        : convertHtmlToText(pageContext.answer)
                }
            />
            <PopularQuestion question={pageContext} questionID={questionID} />
        </Layout>
    );
}
