import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import Answer from "../detailsanswer/answer.js";
import InnerSocial from "../detailsanswer/shares.js";
import share from "../assets/images/ans.svg";
import { getTextFromHTML } from '../../../utils/utils'

export default function PopularQuestion({ question, questionID }) {
    const [detailquestion, setdeatailquestion] = useState(null);

    console.log("DATA DETAIL: ", detailquestion)

    useEffect(() => {

    }, []);

    return (
        <div className="detail-answer">
            <div className="content">
                <Row>
                    <Col sm={12} md={9}>
                        <div className="question-box">
                            {question.subtopic_icon &&
                                <img
                                    src={
                                        question.subtopic_icon || null
                                    }
                                    className="tag"
                                />
                            }
                            <h1>{question.question}</h1>
                            <div className="social-countr found-socials">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                        <img src={share} />
                                        <span> SHARE</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <InnerSocial
                                            link={`https://fertility.medanswers.com/popular-questions/details/${question.id}`}
                                            title={question.answer}
                                            _id={question.id}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>

                                <div></div>
                            </div>
                            <div className="expected-answer">
                                <h2>ACCEPTED ANSWER</h2>
                                <div className="user-profile">
                                    <Answer
                                        userImg={question?.user_avatar?.url || null}
                                        address={question?.user_location || ""}
                                        affiliation={question?.user_affiliations || ""}
                                        userName={question?.user_name || ""}
                                        userDesignation={question?.user_specialities || ""}
                                        answer={question?.answer ? getTextFromHTML(question?.answer) : ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
